import React from "react";
//import { useNavigate, Navigate } from "react-router-dom";
//import { AuthContext } from "../../App";
import "./Card.css";

import { Apple, Download } from "../_common/Icons";

export default function Card({ action, image, title, body, details, link }) {
  //const { signIn } = React.useContext(AuthContext);
  //const navigate = useNavigate();

  const [requesting, setRequesting] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  function handleAction() {
    //navigate("/dashboard");
    setRequesting(true);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: 30,
        margin: 10,
        width: 400,
        //height: 150,
        backgroundColor: "#333",
        borderRadius: 20,
        borderColor: "#3eb2fd ",
        borderWidth: 2,
        borderStyle: "solid",
      }}
    >
      <div style={{ flex: 1, padding: 10 }}>
        <div>
          <Apple size={50} fill={"#fff"} />
        </div>
      </div>

      <div style={{ flex: 4 }}>
        <span style={{ fontSize: 18, fontWeight: 700 }}>{title}</span>
        <br />
        <span style={{ fontSize: 12, fontWeight: 500 }}>{body}</span>
        <br />
        <span style={{ fontSize: 12, fontWeight: 500 }}>{details}</span>
      </div>
      <div style={{ flex: 1, padding: 10 }}>
        <div>
          <Download size={50} fill={"#fff"} />
        </div>
      </div>
    </div>
  );
}
