//import RNFS from 'react-native-fs';

//Cookie Session Not Secure change later
import Cookies from "universal-cookie";

//const apiURI = 'http://192.168.0.158:3031/v1/';
const apiURI = "https://api.proflowdit.com/v1/";
//const sessionURI = "http://localhost:3000/"; //"https://api.proflowdit.com/"; //"http://localhost:3000/"; //"https://api.proflowdit.com/";
const sessionURI = "https://api.proflowdit.com/"; //"http://localhost:3000/"; //"https://api.proflowdit.com/";
const rootURI = "https://api.proflowdit.com/";

/**/
async function getAuth() {
  //let path = RNFS.DocumentDirectoryPath + '/_ProFlow_Social/_init/init.txt';

  //let file = await RNFS.readFile(path, 'utf8');
  //let data = JSON.parse(file);
  //console.log('Read Init File:', data);
  const cookies = new Cookies();
  let auth = cookies.get("pf_token");
  //auth = JSON.parse(auth);
  console.log(auth);

  let authToken = auth;
  return authToken;
}

//Need to implement session login for web-spa

//Fetch GET
async function fetchFromAPI(endpoint, params) {
  //const authToken = await getAuth();
  const url = apiURI + endpoint; //+ '/' + params.id;

  //BUILD POST REQUEST.
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Accept", "application/json");
  //myHeaders.append('Authorization', 'Bearer ' + authToken);
  //myHeaders.append('x-auth', authToken);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    //body: formBody, //formData, //urlencoded,
    redirect: "follow",
  };

  let res = await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode === 401) {
        console.log("Unauthorized", data);
        return { message: "Unauthorized", code: 401, data: data };
      } else {
        console.log("Success", data);
        return { message: "Success", code: 200, data: data };
      }
    })
    .catch((error) => {
      console.log("Fetch Error:", error);
      return { message: "Error: " + error, code: 500, data: [] };
    });

  return res;
}

async function fetchFromAPIAuth(endpoint, params) {
  const authToken = await getAuth();
  const url = apiURI + endpoint; //+ '/' + params.id;

  //BUILD POST REQUEST.
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer " + authToken);
  myHeaders.append("x-auth", authToken);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    //body: formBody, //formData, //urlencoded,
    redirect: "follow",
    credentials: "include",
  };

  let res = await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode === 401) {
        console.log("Unauthorized", data);
        return { message: "Unauthorized", code: 401, data: data };
      } else {
        console.log("Success", data);
        return { message: "Success", code: 200, data: data };
      }
    })
    .catch((error) => {
      console.log("Fetch Error:", error);
      return { message: "Error: " + error, code: 500, data: [] };
    });

  return res;
}

async function fetchFromAPISession(endpoint, params) {
  const authToken = await getAuth();
  const url = sessionURI + endpoint; //+ '/' + params.id;

  //BUILD POST REQUEST.
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Accept", "application/json");
  //myHeaders.append("Authorization", "Bearer " + authToken);
  //myHeaders.append("x-auth", authToken);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    //body: formBody, //formData, //urlencoded,
    redirect: "follow",
    credentials: "include",
    withCredentials: true,
  };

  let res = await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode === 401) {
        console.log("Unauthorized", data);
        return { message: "Unauthorized", code: 401, data: data };
      } else {
        console.log("Success", data);
        return { message: "Success", code: 200, data: data };
      }
    })
    .catch((error) => {
      console.log("Fetch Error:", error);
      return { message: "Error: " + error, code: 500, data: [] };
    });

  return res;
}

////POST

async function postToRoot(endpoint, formData, params) {
  //const authToken = await getAuth();
  const url = rootURI + endpoint;

  //BUILD POST REQUEST.
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Accept", "application/json");
  //myHeaders.append('Authorization', 'Bearer ' + authToken);
  //myHeaders.append('x-auth', authToken);

  /*
    var formData = {
    username: email, //'mikepfau@gmail.com', //data.email,
    email: email, //'mikepfau@gmail.com', //data.email,
    password: password, //'12345678', //data.password,
    //grant_type: "password",
    };
    */

  var formBody = [];
  for (var property in formData) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(formData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formBody, //formData, //urlencoded,
    redirect: "follow",
    credentials: "include",
  };

  let res = await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode === 401) {
        console.log("POST Unauthorized", data);
        return { message: "Unauthorized", code: 401, data: data };
      } else {
        console.log("POST Success", data);
        return { message: "Success", code: 200, data: data };
      }
    })
    .catch((error) => {
      console.log("Fetch Error:", error);
      return { message: "Error: " + error, code: 500, data: [] };
    });

  return res;
}

async function postToAPI(endpoint, formData, params) {
  //const authToken = await getAuth();
  const url = apiURI + endpoint;

  //BUILD POST REQUEST.
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Accept", "application/json");
  //myHeaders.append('Authorization', 'Bearer ' + authToken);
  //myHeaders.append('x-auth', authToken);

  /*
    var formData = {
    username: email, //'mikepfau@gmail.com', //data.email,
    email: email, //'mikepfau@gmail.com', //data.email,
    password: password, //'12345678', //data.password,
    //grant_type: "password",
    };
    */

  var formBody = [];
  for (var property in formData) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(formData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formBody, //formData, //urlencoded,
    redirect: "follow",
    credentials: "include",
  };

  let res = await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode === 401) {
        console.log("POST Unauthorized", data);
        return { message: "Unauthorized", code: 401, data: data };
      } else {
        console.log("POST Success", data);
        return { message: "Success", code: 200, data: data };
      }
    })
    .catch((error) => {
      console.log("Fetch Error:", error);
      return { message: "Error: " + error, code: 500, data: [] };
    });

  return res;
}

async function postToAPIAuth(endpoint, formData, params) {
  const authToken = await getAuth();
  const url = apiURI + endpoint;

  //BUILD POST REQUEST.
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", "Bearer " + authToken);
  myHeaders.append("x-auth", authToken);

  /*
    var formData = {
    username: email, //'mikepfau@gmail.com', //data.email,
    email: email, //'mikepfau@gmail.com', //data.email,
    password: password, //'12345678', //data.password,
    //grant_type: "password",
    };
    */

  var formBody = [];
  for (var property in formData) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(formData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formBody, //formData, //urlencoded,
    redirect: "follow",
    credentials: "include",
  };

  let res = await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode === 401) {
        console.log("POST Unauthorized", data);
        return { message: "Unauthorized", code: 401, data: data };
      } else {
        console.log("POST Success", data);
        return { message: "Success", code: 200, data: data };
      }
    })
    .catch((error) => {
      console.log("Fetch Error:", error);
      return { message: "Error: " + error, code: 500, data: [] };
    });

  return res;
}

async function postToAPISession(endpoint, formData, params) {
  const url = sessionURI + endpoint;

  //BUILD POST REQUEST.
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Accept", "application/json");
  //myHeaders.append("Authorization", "Bearer " + authToken);
  //myHeaders.append("x-auth", authToken);

  /*
    var formData = {
    username: email, //'mikepfau@gmail.com', //data.email,
    email: email, //'mikepfau@gmail.com', //data.email,
    password: password, //'12345678', //data.password,
    //grant_type: "password",
    };
    */

  var formBody = [];
  for (var property in formData) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(formData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formBody, //formData, //urlencoded,
    redirect: "follow",
    credentials: "include",
    withCredentials: true,
  };

  let res = await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode === 401) {
        console.log("POST Unauthorized", data);
        return { message: "Unauthorized", code: 401, data: data };
      } else {
        console.log("POST Success", data);
        return { message: "Success", code: 200, data: data };
      }
    })
    .catch((error) => {
      console.log("Fetch Error:", error);
      return { message: "Error: " + error, code: 500, data: [] };
    });

  return res;
}

export {
  postToRoot,
  fetchFromAPI,
  postToAPI,
  fetchFromAPIAuth,
  postToAPIAuth,
  fetchFromAPISession,
  postToAPISession,
};
