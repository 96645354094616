import React from "react";

import {
  fetchFromAPISession,
  postToAPISession,
} from "../../components/_utils/database";
import { Link } from "react-router-dom";

export default function ProjectPage() {
  const [projects, setProjects] = React.useState();

  async function init() {
    //
  }

  async function getProjects() {
    let resProjects = await fetchFromAPISession("projects");

    setProjects(resProjects.data);
    console.log("resProjects.data", resProjects.data);
  }

  React.useEffect(() => {
    const start = async () => {
      //init();
      await getProjects();
    };
    start();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        //flex: 4,
        margin: 30,
        //marginRight: 30,
      }}
    >
      {projects &&
        projects.map((item, key) => {
          return (
            <div key={key}>
              <div>
                <Link to={"/project/" + item.id}>{item.name}</Link>
                <p>{item.directory.md5}</p>
                {item.directory.files.map((item2, index2) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyItems: "flex-start",
                        //backgroundColor: "#fff",
                        borderRadius: 10,
                        margin: 10,
                      }}
                    >
                      <div
                        style={{
                          aspectRatio: 1,
                          width: 50,
                          backgroundColor: "#fff",
                          borderRadius: 10,
                          marginRight: 10,
                        }}
                      ></div>
                      <p className="text-base">{item2.filename}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

      <p>{/*user.username*/}</p>
    </div>
  );
}
