import React from "react";

import "./SupportPage.css";

import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

export default function SupportPage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        //flex: 4,
        marginLeft: 100,
        marginRight: 100,
        marginTop: 50,
        overflow: "scroll",
      }}
    >
      <div>
        <div>
          <span className="text-3xl font-bold">What is ProFlow?</span>
          <br></br>
          <span className="text-base font-normal">
            ProFlow is a tool to help remotely sync heavy content for
            professional editing workflows.
          </span>
          <br></br>
          <a href="/" download>
            ProFlowDIT version 0.0.1-alpha
          </a>
        </div>
      </div>

      <p>{/*user.username*/}</p>
      <div style={{ height: 100 }}></div>
      <Accordion>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <span className="text-3xl font-bold">
                  1. Download and Install latest version of desktop application
                </span>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <span className="text-lg font-normal">
              MacOSX and iOS are currently supported. Monterrey or later.
            </span>
            <br></br>
            <span className="text-base font-normal">
              MacOSX and iOS are currently supported. Monterrey or later.
            </span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            <img src="./62fb91c171e3a7cde26d6e15_AppStore.svg" alt="appStore" />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <span className="text-3xl font-bold">
                  2. Installation Permission
                </span>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            You will need to right click the app.dmg to open it.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <span className="text-3xl font-bold">
                  3. Setting up Archives. Very important step.
                </span>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            In settings menu. click the archive you would like to use. Large SSD
            is preferred.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <span className="text-3xl font-bold">
                  4. Creating a project
                </span>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <span className="text-3xl font-bold">5. DIT Flow</span>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <span className="text-3xl font-bold">
                  6. Uploading a project
                </span>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <span className="text-3xl font-bold">
                  7. Remote Syncing a Project
                </span>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </AccordionPanel>
        </AccordionItem>
       
      </Accordion>
    </div>
  );
}
