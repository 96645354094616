import React from "react";
import "../../App.css";
import PrivateMenu from "../../components/Menu/PrivateMenu";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

import {
  fetchFromAPISession,
  postToAPISession,
} from "../../components/_utils/database";

export default function ProjectDetail() {
  const [projects, setProjects] = React.useState();

  async function init() {
    //
  }

  async function getProjects() {
    let resProjects = await fetchFromAPISession("projects");

    setProjects(resProjects.data);
    console.log("resProjects.data", resProjects.data);
  }

  React.useEffect(() => {
    const start = async () => {
      //init();
      await getProjects();
    };
    start();
  }, []);

  return (
    <div className="App">
      <PrivateMenu />

      <header className="App-section">
        <div
          style={{ height: 60, backgroundColor: "red", width: "100%" }}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            //height: "100%",
            flex: 1,
            margin: 30,
            //marginRight: 30,
          }}
        >
          {projects &&
            projects.map((item, key) => {
              return (
                <div key={key}>
                  <div>
                    <p>{item.name}</p>
                    {item.directory.files.map((item2, index2) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyItems: "flex-start",
                            //backgroundColor: "#fff",
                            borderRadius: 10,
                            margin: 10,
                          }}
                        >
                          <div
                            style={{
                              aspectRatio: 1,
                              width: 50,
                              backgroundColor: "#fff",
                              borderRadius: 10,
                              marginRight: 10,
                            }}
                          ></div>
                          <p className="text-base">{item2.filename}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          <p>{/*user.username*/}</p>
          <Tabs isFitted variant="enclosed">
            <TabList mb="1em">
              <Tab>
                <p className="text-base">Overview</p>
              </Tab>
              <Tab>Timeline</Tab>
              <Tab>Team</Tab>
              <Tab>Budget</Tab>
              <Tab>Legal</Tab>
              <Tab>Files</Tab>
              <Tab>Reviews</Tab>
              <Tab>Delivery</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <p>one!</p>
              </TabPanel>
              <TabPanel>
                <p>two!</p>
              </TabPanel>
              <TabPanel>
                <p>three!</p>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </header>
    </div>
  );
}
