import React, { useContext, useRef } from "react";
//import { AppContext } from "../../components/_context/AppContext";

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

import { useDisclosure } from "@chakra-ui/react";

import PrivateMenu from "../../components/Menu/PrivateMenu";

import {
  fetchFromAPISession,
  postToAPISession,
} from "../../components/_utils/database";

import SupportPage from "./SupportPage";
import ProjectPage from "./ProjectList";

export default function Dashboard(props) {
  //const appContext = useContext(AppContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const [user, setUser] = React.useState("");
  const [projects, setProjects] = React.useState();
  const [subMenu, setSubMenu] = React.useState("projects");

  async function init() {
    //
  }

  async function getProjects() {
    let resProjects = await fetchFromAPISession("projects");

    setProjects(resProjects.data);
  }

  React.useEffect(() => {
    const start = async () => {
      //init();
      await getProjects();
    };
    start();
  }, []);

  return (
    <div className="App">
      <PrivateMenu />

      <header className="App-section">
        <div
          style={{
            width: "100vw",
            height: "calc(100vh - 80px)",
            display: "flex",
            flexDirection: "column",
            marginTop: 40,
          }}
        >
          <Button ref={btnRef} colorScheme="teal" onClick={onOpen}>
            Menu
          </Button>
          {subMenu === "projects" ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 100,
                  backgroundColor: "#222",
                }}
              >
                <h2 style={{ fontWeight: 700 }}>Projects</h2>
              </div>
              <ProjectPage></ProjectPage>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 100,
                  backgroundColor: "#222",
                }}
              >
                <h2 style={{ fontWeight: 700 }}>Installation & Setup</h2>
              </div>
              <SupportPage></SupportPage>
            </>
          )}
        </div>
      </header>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Welcome</DrawerHeader>

          <DrawerBody>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                //alignItems: "center",
                //justifyContent: "center",
                flex: 1,
                backgroundColor: "#111",
              }}
            >
              <button
                href="#"
                style={{
                  color: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  //flex: 1,
                  backgroundColor: "#222",
                  padding: 5,
                  //border:'1px solid #fff'
                  border: "none",
                }}
                onClick={() => {
                  setSubMenu("projects");
                }}
              >
                <p>Projects</p>
              </button>
              <button
                style={{
                  color: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  //flex: 1,
                  backgroundColor: "#222",
                  padding: 5,
                  //border:'1px solid #fff'
                  border: "none",
                }}
                onClick={() => {
                  setSubMenu("support");
                }}
              >
                <p>Support</p>
              </button>
            </div>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue">Save</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
}
