import React, { useContext } from "react";

import { AuthContext } from "../../App";
//import { AppContext } from "../../components/_context/AppContext";

import PrivateMenu from "../../components/Menu/PrivateMenu";
import Button from "../../components/Button";

import Cookies from "universal-cookie";

export default function Account({}) {
  const { signOut, getIsSignout, getUserData } = useContext(AuthContext);

  const [user, setUser] = React.useState("");

  async function authInit() {
    //
  }

  React.useEffect(() => {
    const start = async () => {
      //authInit();
      let res = await getUserData();
      setUser(res);
    };
    start();
  }, []);

  return (
    <div className="App">
      <PrivateMenu />
      <header className="App-section">
        <h1 style={{ fontWeight: 700 }}>Account</h1>
        <div
          style={{
            width: 100,
            height: 100,
            border: "1px #fff solid",
            borderRadius: "100%",
          }}
        ></div>
        <p>{user.username}</p>
        <p>{user.role}</p>
        <p>{user.status}</p>
        <Button type={"func"} action={signOut} title="SignOut" />
      </header>
    </div>
  );
}
