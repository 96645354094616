import React, { useContext } from "react";
//import { AppContext } from "../../components/_context/AppContext";

import Menu from "../../components/Menu/Menu";
import { Link } from "react-router-dom";

import Card from "../../components/Card";
import PriceCard from "../../components/Card/PriceCard";
import Button from "../../components/Button";

import { Apple } from "../../components/_common/Icons";

import Cookies from "universal-cookie";

import { Box, Flex } from "@chakra-ui/react";

export default function Home({ state }) {
  //const appContext = useContext(AppContext);
  const [email, setEmail] = React.useState("");

  async function init() {
    //BUILD POST REQUEST.
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    //myHeaders.append("Accept", "application/json");
    //myHeaders.append("Authorization", "Bearer " + authToken);
    //myHeaders.append("x-auth", authToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      //body: formBody, //formData, //urlencoded,
      redirect: "follow",
    };

    let res = await fetch("https://api.proflowdit.com/isLoggedIn", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 401) {
          console.log("Unauthorized", data);
          return { message: "Unauthorized", code: 401, data: data };
        } else {
          console.log("Success", data);
          return { message: "Success", code: 200, data: data };
        }
      })
      .catch((error) => {
        console.log("Fetch Error:", error);
        return { message: "Error: " + error, code: 500, data: [] };
      });
  }

  
  React.useEffect(() => {
    const start = async () => {
      //init();
      //authInit();
    };
    start();
  }, []);

  return (
    <div className="App">
      <Menu state={state} />
      {/**LANDING BILLBOARD ///////////////////////////////////////////////////////////////////////////////////////////// */}
      <header
        className="App-section"
        style={{
          display: "none",
          backgroundImage: "url('./pexels-cottonbro-studio-2925322.jpeg') ",
          boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.5)",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <h2 style={{ color: "#3eb2fd", fontWeight: 700, lineHeight: "100%" }}>
          Developed by Filmmakers for <br></br>Industry Professionals
        </h2>
      </header>
      {/**PRODUCT HIGHLIGHT - SOFTWARE DOWNLOAD ///////////////////////////////////////////////////////////////////////////////////////////// */}
      <header
        className="App-section"
        style={{
          backgroundImage: "url('./banner.jpg')",
          boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.5)",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <h2 style={{ display: "none", fontWeight: 700, lineHeight: "100%" }}>
          Work Smarter not Harder,Scale Creativity
        </h2>
        <h2
          style={{
            display: "none",
            color: "#3eb2fd",
            fontWeight: 700,
            lineHeight: "100%",
          }}
        >
          Tools for professional filmmakers
        </h2>
        <PriceCard
          title={"ProFlow Social - Private Beta"}
          body={"Invite Only"}
          details={"Send request to be a part of our beta program."}
          link={"/register?tier=cinema"}
        />

        <div style={{ display: "none" }}>
          <Card
            title={"Desktop - ProFlow 0.1"}
            body={"MacOS - 11.5+(Monterey)"}
            details={"Intel / Apple Silicon Download"}
            link={"/login"}
          />
          <Card
            title={"Tablet/Mobile"}
            body={"iOS / iPadOS | 13+"}
            details={"App Store"}
            link={"/login"}
          />
        </div>
        <h6 style={{ display: "none", fontWeight: 700, lineHeight: "100%" }}>
          *We are currently in private beta.
        </h6>
      </header>
      {/**TAGLINE  ///////////////////////////////////////////////////////////////////////////////////////////// */}
      <header
        className="App-section-third"
        style={{
          display: "none",
          //backgroundImage: "url('./banner.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Flex align="center" justify="center">
          Flex Container
        </Flex>
        <h1 className="text-3xl font-bold underline">Hello world!</h1>
        <h3 style={{ fontWeight: 700, margin: 50, lineHeight: "100%" }}>
          Developed by Filmmakers for <br></br>Industry Professionals
        </h3>
      </header>
      {/**HERO - CONTENT MGMT ///////////////////////////////////////////////////////////////////////////////////////////// */}
      <header
        className="App-section-third"
        style={{
          display: "none",
          //backgroundImage: "url('./banner.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div style={{ display: "flex", width: 1024 }}>
          <div
            style={{
              flex: 1,
              //minWidth: 100,
              //minHeigh: 100,
              textAlign: "center",
              //backgroundImage: "url('./banner.jpg')",
              backgroundPosition: "center",
              backgroundSize: "cover",
              justifyContent: "center",
              alignItems: "center",
              //aspectRatio: 1,
            }}
          >
            <h3
              style={{
                color: "#3eb2fd",
                fontWeight: 700,
                marginRight: 5,
                lineHeight: "100%",
              }}
            >
              Digital Asset Management
            </h3>
          </div>
          <div
            style={{ flex: 3, marginLeft: 5, borderLeft: "#3eb2fd 4px solid" }}
          >
            <ul>
              <li>
                <span style={{ fontSize: 24, fontWeight: 500 }}>
                  Professional content ingest and logging
                </span>
              </li>
              <li>
                <span style={{ fontSize: 24, fontWeight: 500 }}>
                  Automated Timeline and Proxy Distribution for global remote
                  teams
                </span>
              </li>
              <li>
                <span style={{ fontSize: 24, fontWeight: 500 }}>
                  Get creative faster and share intermediates with ease{" "}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </header>
      {/**HERO - PROJECT MGMT  ///////////////////////////////////////////////////////////////////////////////////////////// */}
      <header
        className="App-section-third"
        style={{
          display: "none",
          //backgroundImage: "url('./banner.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div style={{ display: "flex", width: 1024 }}>
          <div
            style={{
              flex: 1,
              //minWidth: 100,
              //minHeigh: 100,
              textAlign: "center",
              //backgroundImage: "url('./banner.jpg')",
              backgroundPosition: "center",
              backgroundSize: "cover",
              justifyContent: "center",
              alignItems: "center",
              //aspectRatio: 1,
            }}
          >
            <h3
              style={{
                color: "#3eb2fd",
                fontWeight: 700,
                marginRight: 5,
                lineHeight: "100%",
              }}
            >
              Project Management
            </h3>
          </div>
          <div
            style={{ flex: 3, marginLeft: 5, borderLeft: "#3eb2fd 4px solid" }}
          >
            <ul>
              <li>
                <span style={{ fontSize: 24, fontWeight: 500 }}>
                  Simple Automated interface for distributed gloabl project
                  managment
                </span>
              </li>
              <li>
                <span style={{ fontSize: 24, fontWeight: 500 }}>
                  Deeply intergrated with asset and delivery pipeline
                </span>
              </li>
              <li>
                <span style={{ fontSize: 24, fontWeight: 500 }}>
                  Locale aware to get projects delivered on-time.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </header>
      {/**HERO - REMOTE HEAVY CONTENT ///////////////////////////////////////////////////////////////////////////////////////////// */}
      <header
        className="App-section-third"
        style={{
          display: "none",
          //backgroundImage: "url('./banner.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div style={{ display: "flex", width: 1024 }}>
          <div
            style={{
              flex: 1,
              //minWidth: 100,
              //minHeigh: 100,
              textAlign: "center",
              //backgroundImage: "url('./banner.jpg')",
              backgroundPosition: "center",
              backgroundSize: "cover",
              justifyContent: "center",
              alignItems: "center",
              //aspectRatio: 1,
            }}
          >
            <h3
              style={{
                color: "#3eb2fd",
                fontWeight: 700,
                marginRight: 5,
                lineHeight: "100%",
              }}
            >
              Distributed Remote Post-Production
            </h3>
          </div>
          <div
            style={{ flex: 3, marginLeft: 5, borderLeft: "#3eb2fd 4px solid" }}
          >
            <ul>
              <li>
                <span style={{ fontSize: 24, fontWeight: 500 }}>
                  Specially designed workflows to get creatives quickly into
                  content
                </span>
              </li>
              <li>
                <span style={{ fontSize: 24, fontWeight: 500 }}>
                  Streamline draft, screening and delivery workflows
                </span>
              </li>
              <li>
                <span style={{ fontSize: 24, fontWeight: 500 }}>
                  Simultaneous collabarotive global workflows
                </span>
              </li>
            </ul>
          </div>
        </div>
      </header>
      {/**CLIENT LOGOS ///////////////////////////////////////////////////////////////////////////////////////////// */}
      <header
        className="App-section-half"
        style={{
          display: "none",
          //backgroundImage: "url('./banner.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div style={{ display: "flex", width: "100vw" }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "15vw",
              //backgroundImage: "url('./banner.jpg')",
              backgroundColor: "#111",
              backgroundPosition: "center",
              backgroundSize: "cover",
              aspectRatio: 1,
              justifyContent: "center",
              alignItems: "center",
              margin: 10,
              textAlign: "center",
            }}
          >
            <div>
              <img
                src="./clients/gopro.png"
                className="Client-logo"
                alt="logo"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "20vw",
              //backgroundImage: "url('./banner.jpg')",
              backgroundColor: "#111",
              backgroundPosition: "center",
              backgroundSize: "cover",
              aspectRatio: 1,
              justifyContent: "center",
              alignItems: "center",
              margin: 10,
              textAlign: "center",
            }}
          >
            <div>
              <img
                src="./clients/wige.png"
                className="Client-logo-invert"
                alt="logo"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "20vw",
              //backgroundImage: "url('./banner.jpg')",
              backgroundColor: "#111",
              backgroundPosition: "center",
              backgroundSize: "cover",
              aspectRatio: 1,
              justifyContent: "center",
              alignItems: "center",
              margin: 10,
              textAlign: "center",
            }}
          >
            <div>
              <img
                src="./clients/tropicfeel.png"
                className="Client-logo"
                alt="logo"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "20vw",
              //backgroundImage: "url('./banner.jpg')",
              backgroundColor: "#111",
              backgroundPosition: "center",
              backgroundSize: "cover",
              aspectRatio: 1,
              justifyContent: "center",
              alignItems: "center",
              margin: 10,
              textAlign: "center",
            }}
          >
            <div>
              <span style={{ fontSize: 24, fontWeight: 500 }}>FTI</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "20vw",
              //backgroundImage: "url('./banner.jpg')",
              backgroundColor: "#111",
              backgroundPosition: "center",
              backgroundSize: "cover",
              aspectRatio: 1,
              justifyContent: "center",
              alignItems: "center",
              margin: 10,
              textAlign: "center",
            }}
          >
            <div>
              <span style={{ fontSize: 24, fontWeight: 500 }}>Mink</span>
            </div>
          </div>
        </div>
      </header>
      {/**CTA - PRICING REGISTER ///////////////////////////////////////////////////////////////////////////////////////////// */}
      <header
        className="App-section"
        style={{
          display: "none",
          //backgroundImage: "url('./banner.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <h1 style={{ display: "none", fontWeight: 700 }}>Do 10X more</h1>
        <div style={{ display: "flex" }}>
          {/**
          <PriceCard
            title={"Social"}
            body={"$9.99 /month"}
            details={"Features. Projects, Team, Storage, Backup"}
            link={"/register?tier=social"}
          />
          <PriceCard
            title={"Pro"}
            body={"$24.99 /month"}
            details={"Features. Projects, Team, Storage, Backup"}
            link={"/register?tier=pro"}
          />
          <PriceCard
            title={"Cinema"}
            body={"$99.99 /month"}
            details={"Features. Projects, Team, Storage, Backup"}
            link={"/register?tier=cinema"}
          />
           */}
          <PriceCard
            title={"Private Beta"}
            body={"Invite Only"}
            details={"Send request to be a part of our beta program."}
            link={"/register?tier=cinema"}
          />
        </div>
        {/** 
        <p>Increase production by 1000% </p>
        */}
        {/**
        <Button type={"link"} to="/pricing" title={"Pricing"} />
        <Button type={"link"} to="/why" title={"Why"} />
         */}
      </header>
      {/**TODO: FOOTER ///////////////////////////////////////////////////////////////////////////////////////////// */}
      <header
        className="App-section-quarter"
        style={{
          display: "none",
          //backgroundImage: "url('./banner.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <div
            style={{
              flex: 1,
              minWidth: 100,
              minHeigh: 100,
              //backgroundImage: "url('./banner.jpg')",
              backgroundPosition: "center",
              backgroundSize: "cover",
              aspectRatio: 1,
            }}
          >
            <p>ProFlow Social</p>
          </div>
          <div style={{ flex: 1 }}>
            <p>Site</p>
            <ul>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div style={{ flex: 1 }}>
            <p>User</p>
            <ul>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div style={{ flex: 1 }}>
            <p>Other</p>
            <ul>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
        <div>©2023 ProFlow Social | All rights reserved.</div>
      </header>
    </div>
  );
}
