import React from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { AuthContext } from "../../App";
import "./SignIn.css";

import Menu from "../../components/Menu/Menu";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function SignIn({ navigation, route }) {
  const { signIn } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const [requesting, setRequesting] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  async function appLogin() {
    console.log("FUNC: AppLogin");
    setRequesting(true);
    setMessage("Requesting...");
    //let { status, message } = await signIn(email, password);
    let status = signIn(email, password);

    if (status) {
      console.log("FUNC: AppLogin", status, message);
      navigate("/dashboard");
      //handleNavigate();
      //return <Navigate to="/dashboard" replace></Navigate>;
    }
  }

  function handleNavigate() {
    //navigate("/dashboard");
  }

  return (
    <div className="App">
      <Menu />
      <header className="App-section">
        <h1 style={{ fontWeight: 700 }}>Sign In</h1>
        <form style={{ marginBottom: 20, width: isBrowser ? 400 : 300 }}>
          <div className="form__group field">
            <input
              className="form__field"
              type="input"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            ></input>
            <label htmlFor="email" className="form__label">
              Email
            </label>
          </div>
          <div className="form__group field">
            <input
              className="form__field"
              type="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            ></input>
            <label htmlFor="password" className="form__label">
              Password
            </label>
          </div>
        </form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 30,
            width: 400,
          }}
        >
          <button
            id="submitBtn"
            className="button-21"
            role="button"
            onClick={appLogin}
          >
            Sign In
          </button>
        </div>
        {requesting ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 10,
              width: isBrowser ? 400 : 300,
            }}
          >
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div>
          <p style={{ color: "#ccc", fontSize: 16 }}>{message}</p>
        </div>
      </header>
    </div>
  );
}
