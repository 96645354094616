import React from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { Link } from "react-router-dom";

//import { AuthContext } from "../../App";
import "./Button.css";

export default function Button({ type, to, action, title }) {
  //const { signIn } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const [requesting, setRequesting] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  function handleNav(to) {
    navigate(to);
    //setRequesting(true);
  }
  if (type === "func") {
    //Action Button
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          //marginBottom: 30,
          width: 200,
        }}
      >
        <button
          id="submitBtn"
          className="button-21"
          role="button"
          onClick={action}
        >
          {requesting ? (
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <span>{title}</span>
          )}
        </button>
      </div>
    );
  } else {
    //Default Link
    //handleNav(to)
    return (
      <Link to={to}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            //marginBottom: 30,
            width: 200,
          }}
        >
          <div
            id="submitBtn"
            className="button-21"
            role="button"
            //onClick={action}
          >
            {requesting ? (
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <span>{title}</span>
            )}
          </div>
        </div>
      </Link>
    );
  }
}
