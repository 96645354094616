import React, { useContext, useEffect, useState } from "react";
import "./Menu.css";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { UserCircle } from "../_common/Icons";
import { AuthContext } from "../../App";
import Button from "../Button";

const cookies = new Cookies();

export default function Menu(state) {
  const { signOut, getIsSignout, getUserData } = useContext(AuthContext);
  const [user, setUser] = React.useState("");
  const [isSignout, setIsSignout] = React.useState('');

  async function init() {
    //
  }

  React.useEffect(() => {
    const start = async () => {
      /*
      init();
      if (!authState.isSignout) {
        authInit();
      }
      */
      const authState = await getIsSignout();
      console.log("MENU: authState: ", authState);
      setIsSignout(authState.isSignout);

      const res = await getUserData();
      setUser(res);
    };
    start();
  }, []);

  return (
    <div className="menu-container-transparent">
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "left",
          marginLeft: 10,
        }}
      >
        <Link to="/">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="./ProFlow_Logo_v1.png" className="App-logo2" alt="logo" />
            <h1 style={{ marginLeft: 10, fontWeight: 700 }}>ProFlow</h1>
          </div>
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "right",
          marginRight: 10,
        }}
      >
        <div>
          {isSignout ? (
            <Button type={"link"} to="/login" title={"Login"} />
          ) : (
            <Button
              type={"link"}
              to="/Dashboard"
              title={`User: ${user.username}`}
            />
          )}
        </div>
      </div>
    </div>
  );
}
