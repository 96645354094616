import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useNavigate,
} from "react-router-dom";
import ErrorPage from "./components/_common/ErrorPage";

import logo from "./logo.svg";
import "./App.css";
import Menu from "./components/Menu/Menu";
import Footer from "./components/Footer/Footer";

import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Why from "./pages/Why";

import SignIn from "./pages/Auth/SignIn";

import Account from "./pages/Account";
import Dashboard from "./pages/Dashboard";
import ProjectDetail from "./pages/Project";

//https://tailwindcss.com/docs/guides/create-react-app

import { ChakraProvider } from "@chakra-ui/react";
import chakraTheme from "@chakra-ui/theme";

//import { AppContextProvider } from "./components/_context/AppContext";
//import { AppContext } from "./components/_context/AppContext";
const appContext = {
  appVersion: "0.0.1",
  auth: "https://api.proflowdit.com/auth/",
  api: "https://api.proflowdit.com/api/v1/",
  sessionUri: "https://api.proflowdit.com/",
  cdn: "https://cdn.proflowdit.com/",
  rpc: {
    eth: "https://cloudflare-eth.com/",
    matic: "https://polygon-rpc.com/",
  },
  ipfs: "http://cloudflare.com/ipfs/",
  //nftContract: '',
  //poolContract: '',
  nftAddr: "0x0165878A594ca255338adfa4d48449f69242Eb8F",
  poolAddr: "0xa513E6E4b8f2a923D98304ec87F64353C4D5C853",
  //nftAbi : ABI.abi,
  //poolAbi : ABIPOOL.abi,
  mode: "production",
  version: "0.0.1",
  build: "a0000",
  primaryColor: "#b08f26",
  secondaryColor: "#800080", //purple
  accentColor: "#e91e63",
  backgroundColor: "#000000",
  backgroundColor2: "#111111",
  textColor: "#ffffff",
};

export const AuthContext = React.createContext();

export const AppContext = React.createContext(appContext);
export class AppContextProvider extends React.Component {
  /*
    state = {
      context: counterContextWrapper(this),
    };
    */

  render() {
    return (
      <AppContext.Provider value={appContext}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

function App() {
  React.useEffect(() => {
    document.title = "ProFlow DIT - Time saving tools for Filmmakers";
  }, []);
  /////////////////////////////////////////////////////////////////////////

  //INIT BOOTSTRAP
  React.useEffect(() => {
    //console.log("state", state);
    const bootstrapAsync = async () => {
      let userData;
      let authToken;
      //secureSave("userToken", {});
      //let url = "http://localhost:3000/protected/";
      let url = "https://api.proflowdit.com/protected/";
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          //body: formBody, //formData, //urlencoded,
          redirect: "follow",
          credentials: "include",
          withCredentials: true,
        };

        let res = await fetch(url, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.statusCode === 401 || data.statusCode === 403) {
              console.log("Unauthorized | No Session", data);
              dispatch({ type: "SIGN_OUT" });
              /*
              return {
                message: "RESTORE: Unauthorized",
                code: 401,
                data: data,
              };
              */
            } else {
              console.log("RESTORE: Success", data);
              dispatch({
                type: "RESTORE_TOKEN",
                token: authToken,
                user: data,
              });
              //return { message: "Success", code: 200, data: data };
            }
          })
          .catch((error) => {
            console.log("Fetch Error:", error);
            return { message: "Error: " + error, code: 500, data: [] };
          });
      } catch (e) {
        //No token or
        // Restoring token failed
        //userToken = ''
        //get a new one
        //navigation.navigate("Sigin");
        dispatch({ type: "SIGN_OUT" });
      }
    };

    bootstrapAsync();
    console.log(state);
  }, []);

  //AUTH CONTEXT
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            userToken: action.token,
            user: action.user,
            //isSignout: !action.isAuth,
            isLoading: false,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
            user: action.user,
            //user: action.user
            //userFull:
            //cachedAt: new Date()
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
            user: null,
            //userFull:
            //userFull:
            //cachedAt: new Date()
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
    }
  );

  const authContext = React.useMemo(
    () => ({
      signIn: async (email, password) => {
        /**/
        //const cookies = new Cookies();
        //let sid = await cookies.get("connect.sid");
        //console.log("sid:", sid);

        //The h 

        try {
          //let url = "http://localhost:3000/auth/loginsession";
          let url = "https://api.proflowdit.com/auth/loginsession";

          //BUILD POST REQUEST.
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
          myHeaders.append("Accept", "application/json");
          //myHeaders.append("Content-Type", "multi");

          var details = {
            email: email,
            username: email,
            password: password,
            //grant_type: "password",
          };

          var formBody = [];
          for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
          }
          formBody = formBody.join("&");

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formBody, //formData, //urlencoded,
            redirect: "follow",
            credentials: "include",
            withCredentials: true,
          };

          //console.log("urlencoded", urlencoded);

          await fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              //isAuth = data.isAuth;
              //token = data.sessionToken.sessionToken;
              //user = data.user
              if (data.statusCode === 401 || data.statusCode === 403) {
                console.log("LOGIN: Unauthorized: ", data);
                //alert(JSON.stringify(data));
                //cookies.set("pf_token", "401");
                dispatch({ type: "SIGN_OUT" });
              } else {
                console.log(data);
                //cookies.set("pf_token", JSON.stringify(data.sessionToken));
                //cookies.set("pf_token", data.access_token);
                console.log("LOGIN: Success: ", data);
                //secureSave('userToken', data.sessionToken);
                //secureSave('userData', JSON.stringify(data.user));

                //Save authTOKEN to Document/_init/init.txt

                dispatch({
                  type: "SIGN_IN",
                  token: "cookie session", //data.sessionToken.sessionToken,
                  user: data.User,
                  //session: data.sessionToken,
                });
                //Navigate to dashboard
                //window.location.reload(false);
              }
            });
        } catch (error) {
          console.log("sign in error:", error);
        }

        //
      },
      signOut: () => {
        //props.navigation.navigate('signIn');
        //const cookies = new Cookies();
        //cookies.remove("connect.sid");
        try {
          //let url = "http://localhost:3000/logout";
          let url = "https://api.proflowdit.com/logout";

          //BUILD POST REQUEST.
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
          myHeaders.append("Accept", "application/json");
          //myHeaders.append("Content-Type", "multi");

          var requestOptions = {
            method: "GET",
            headers: myHeaders,
            //body: formBody, //formData, //urlencoded,
            redirect: "follow",
            credentials: "include",
          };

          //console.log("urlencoded", urlencoded);

          fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              //isAuth = data.isAuth;
              //token = data.sessionToken.sessionToken;
              //user = data.user
              if (data.statusCode === 401) {
                console.log(data);
                //alert(JSON.stringify(data));
                //cookies.set("pf_token", "401");
                dispatch({ type: "SIGN_OUT" });
              } else {
                console.log("logout", data);
                dispatch({ type: "SIGN_OUT" });
              }
            });
        } catch (error) {
          console.log("sign out error:", error);
          dispatch({ type: "SIGN_OUT" });
        }
      },
      signUp: async (data) => {
        // In a production app, we need to send user data to server and get a token
        // We will also need to handle errors if sign up failed
        // After getting token, we need to persist the token using `SecureStore`
        // In the example, we'll use a dummy token

        dispatch({ type: "SIGN_IN", token: "dummy-auth-token" });
      },
      //resetPassword();
      getUserData: async () => {
        return state.user;
      },
      getIsSignout: async () => {
        return state;
      },
    }),
    [state.user]
  );

  //NAVIGATION & ROUTES
  const ProtectedRoute = ({ state, children }) => {
    //https://www.robinwieruch.de/react-router-private-routes/
    if (state.isSignout) {
      return <Navigate to="/" replace />;
    }
    return children;
  };

  const ProtectedHome = ({ state, children }) => {
    if (state.isSignout) {
      //return <Navigate to="/" />;
      return <Home />;
    }
    //return <Navigate to="/dashboard" />;
    return <Dashboard />;
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <ProtectedHome state={state}></ProtectedHome>,
      //element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/pricing",
      element: <Pricing />,
    },
    {
      path: "/why",
      element: <Why />,
    },
    {
      path: "/login",
      element: <SignIn />,
    },
    //Protected Routes
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute state={state}>
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: "/project/:name",
      element: (
        <ProtectedRoute state={state}>
          <ProjectDetail />
        </ProtectedRoute>
      ),
    },
    {
      //path: "contacts/:contactId",
      path: "/account",
      element: (
        <ProtectedRoute state={state}>
          <Account />
        </ProtectedRoute>
      ),
    },
  ]);

  //https://www.robinwieruch.de/react-router-private-routes/
  return (
    <AppContextProvider>
      <ChakraProvider>
        <AuthContext.Provider value={authContext}>
          <RouterProvider router={router} />
        </AuthContext.Provider>
      </ChakraProvider>
    </AppContextProvider>
  );
}

export default App;
