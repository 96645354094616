import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";

import "./Menu.css";
import { Link } from "react-router-dom";
import { Grid, UserCircle } from "../_common/Icons";

import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";

import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { extendTheme } from "@chakra-ui/react";

export default function PrivateMenu() {
  const { signOut, getIsSignout, getUserData } = useContext(AuthContext);
  const [user, setUser] = React.useState("");
  const [isSignout, setIsSignout] = React.useState(true);

  async function init() {
    //
  }

  React.useEffect(() => {
    const start = async () => {
      /*
      init();
      if (!authState.isSignout) {
        authInit();
      }
      */
      const authState = await getIsSignout();
      console.log("MENU: authState: ", authState);
      setIsSignout(authState.isSignout);

      const res = await getUserData();
      setUser(res);
    };
    start();
  }, []);

  return (
    <div className="menu-container">
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "left",
          marginLeft: 10,
        }}
      >
        {/*<img src="./ProflowIcon.png" className="App-logo2" alt="logo" />*/}
        <Link to="/dashboard">
          <Grid size={35}></Grid>
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/ProFlow_Logo_v1.png" className="App-logo2" alt="logo" />
          <h1 style={{ marginLeft: 10, fontWeight: 700 }}>ProFlow</h1>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "right",
          marginRight: 10,
        }}
      >
        {/** 
        <Menu>
          <MenuButton as={Button} rightIcon={<UserCircle size={35} />}>
            {user.username}
          </MenuButton>
          <MenuList>
            <MenuItem as={Link} to="/account">
              Account
            </MenuItem>
            <MenuItem>Create a Copy</MenuItem>
            <MenuItem>Mark as Draft</MenuItem>
            <MenuItem>Delete</MenuItem>
            <MenuItem>Attend a Workshop</MenuItem>
          </MenuList>
        </Menu>
        */}
        <Link
          to="/account"
          style={{
            display: "flex",
            //flex: 1,
            alignItems: "center",
            justifyContent: "right",
            //marginRight: 10,
          }}
        >
          <span style={{ fontWeight: "bold", marginRight: 10 }}>
            {user.username}
          </span>

          <UserCircle size={35}></UserCircle>
        </Link>
      </div>
    </div>
  );
}

