import React from "react";
import Menu from "../../components/Menu/Menu";



export default function Why() {
    return (
      <div className="App">
        <Menu />
        <header className="App-section">
          <h1 style={{ fontWeight: 700 }}>Why</h1>
        </header>
      </div>
    );
  }
