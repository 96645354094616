import React from "react";
//import { useNavigate, Navigate } from "react-router-dom";
//import { AuthContext } from "../../App";
import "./Card.css";

import { Apple, Download } from "../_common/Icons";

import Button from "../Button";

import { postToRoot } from "../../components/_utils/database";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function PriceCard({
  action,
  image,
  title,
  body,
  details,
  link,
}) {
  //const { signIn } = React.useContext(AuthContext);
  //const navigate = useNavigate();

  const [requesting, setRequesting] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  async function handleAction() {
    //navigate("/dashboard");
    console.log("Send Email:", email);
    setRequesting(true);
    await handleSubscriber();
    //sendEmail(email);
  }

  //Handle subscriber
  async function handleSubscriber() {
    var formData = {
      //username: email, //'mikepfau@gmail.com', //data.email,
      email: email, //'mikepfau@gmail.com', //data.email,
      //password: password, //'12345678', //data.password,
      //grant_type: "password",
    };

    let response = await postToRoot("subscribe", formData, {})
      .then((res) => {
        //console.log('res', res);
        if (res.code === 400) {
          //signOut();
          console.log("res", res);
          setRequesting(false);
        }
        setSuccess(true);
        console.log("res", res);
        setRequesting(false);
        //setData(res.data);
        //setIsSuccess('Success');
        //navigation.goBack();
      })
      .catch((error) => {
        //setIsSuccess('Failed', error);
        console.log("Error: ", error);
        setRequesting(false);
      });
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: isBrowser ? "row" : "column",
        marginBottom: 30,
        margin: 10,
        padding: 15,
        width: isBrowser ? 600 : 300,
        height: 400,
        backgroundColor: "#111111cc",
        borderRadius: 20,
        borderColor: "#111",
        borderWidth: 2,
        borderStyle: "solid",
      }}
    >
      <div style={{ display: "flex", flex: 1, padding: 10 }}>
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          <img src="./ProFlow_Logo_v1.png" className="App-logo2" alt="logo" />
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", flex: 4 }}>
        <div style={{ marginTop: 20, flex: 1 }}>
          <span style={{ color: "#3eb2fd", fontSize: 24, fontWeight: 700 }}>
            {title}
          </span>
          <br />
          <span style={{ fontSize: 12, fontWeight: 500 }}>{body}</span>
          <br />
          <span style={{ fontSize: 12, fontWeight: 500 }}>{details}</span>
        </div>

        <div style={{ flex: 1, marginRight: 30 }}>
          <div className="form__group field" style={{ flex: 1 }}>
            <input
              className="form__field"
              type="input"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            ></input>
            <label htmlFor="email" className="form__label">
              Email
            </label>
            <div style={{ height: 10 }}></div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type={"func"}
                action={handleAction}
                to="/pricing"
                title={"Request Access"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
